export { default as Cta } from '../../components/Cta.vue'
export { default as CtaGhost } from '../../components/CtaGhost.vue'
export { default as Loader } from '../../components/Loader.vue'
export { default as Loading } from '../../components/Loading.vue'
export { default as ContactCard } from '../../components/Contact/ContactCard.vue'
export { default as ContactCardLink } from '../../components/Contact/ContactCardLink.vue'
export { default as Construction } from '../../components/Construction/Construction.vue'
export { default as CookieBanner } from '../../components/CookieBanner/CookieBanner.vue'
export { default as CookieBannerPanel } from '../../components/CookieBanner/CookieBannerPanel.vue'
export { default as Dashboard } from '../../components/Dashboard/Dashboard.vue'
export { default as DashboardNotification } from '../../components/Dashboard/DashboardNotification.vue'
export { default as Documents } from '../../components/Documents/Documents.vue'
export { default as DocumentsItem } from '../../components/Documents/DocumentsItem.vue'
export { default as FormIntern } from '../../components/Form/FormIntern.vue'
export { default as FormInternAppointment } from '../../components/Form/FormInternAppointment.vue'
export { default as FormInternDemo } from '../../components/Form/FormInternDemo.vue'
export { default as FormInternForm } from '../../components/Form/FormInternForm.vue'
export { default as FormInternInfo } from '../../components/Form/FormInternInfo.vue'
export { default as Gallery } from '../../components/Gallery/Gallery.vue'
export { default as GalleryFullscreenZoom } from '../../components/Gallery/GalleryFullscreenZoom.vue'
export { default as Language } from '../../components/Language/Language.vue'
export { default as Localisation } from '../../components/Localisation/Localisation.vue'
export { default as LocalisationMap } from '../../components/Localisation/LocalisationMap.vue'
export { default as Lot } from '../../components/Lot/Lot.vue'
export { default as Menu } from '../../components/Menu/Menu.vue'
export { default as MenuLandingPageMobile } from '../../components/Menu/MenuLandingPageMobile.vue'
export { default as MenuMobile } from '../../components/Menu/MenuMobile.vue'
export { default as Messages } from '../../components/Messages/Messages.vue'
export { default as News } from '../../components/News/News.vue'
export { default as NewsFullscreen } from '../../components/News/NewsFullscreen.vue'
export { default as Notifications } from '../../components/Notifications/Notifications.vue'
export { default as PoweredBy } from '../../components/PoweredBy/PoweredBy.vue'
export { default as Program } from '../../components/Program/Program.vue'
export { default as Projects } from '../../components/Projects/Projects.vue'
export { default as ProjectsDelete } from '../../components/Projects/ProjectsDelete.vue'
export { default as ProjectsItem } from '../../components/Projects/ProjectsItem.vue'
export { default as ProjectsShare } from '../../components/Projects/ProjectsShare.vue'
export { default as TheHeader } from '../../components/TheHeader/TheHeader.vue'
export { default as TheHeaderLandingPage } from '../../components/TheHeader/TheHeaderLandingPage.vue'
export { default as UICard } from '../../components/UI/Card.vue'
export { default as UiBtnBurger } from '../../components/UI/UiBtnBurger.vue'
export { default as UiBtnClose } from '../../components/UI/UiBtnClose.vue'
export { default as UiBtnReturn } from '../../components/UI/UiBtnReturn.vue'
export { default as UserIdentification } from '../../components/User/UserIdentification.vue'
export { default as UserInfos } from '../../components/User/UserInfos.vue'
export { default as UserLogin } from '../../components/User/UserLogin.vue'
export { default as UserPasswordRecovery } from '../../components/User/UserPasswordRecovery.vue'
export { default as UserSignin } from '../../components/User/UserSignin.vue'
export { default as VisitLink } from '../../components/VisitLink/VisitLink.vue'
export { default as VisitLinkSmall } from '../../components/VisitLink/VisitLinkSmall.vue'
export { default as FormExternQr } from '../../components/FormExtern/FormExternQr/FormExternQr.vue'
export { default as FormExternQrAppt } from '../../components/FormExtern/FormExternQr/FormExternQrAppt.vue'
export { default as FormExternQrForm } from '../../components/FormExtern/FormExternQr/FormExternQrForm.vue'
export { default as FormExternQrInfo } from '../../components/FormExtern/FormExternQr/FormExternQrInfo.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
